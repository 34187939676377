import React from 'react';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import '../../../node_modules/react-bootstrap-modal/lib/css/rbm-complete.css';
import { Link } from 'react-router-dom';
import videoImg from '../../assets/images/video-img.jpg';

var Modal = require('react-bootstrap-modal');
 
class ScheduleAppointment extends React.Component {
    state = {
        isOpen: false,
        success: false,
        formFields: {
            name: '',
            email: '',
            phone: '',
            text: '',
            preferred: '',
            service: 'Individual'
        }
    }

    
    onSubmit = (e) => {
        e.preventDefault();
        const data = this.state.formFields;
        fetch('/api/contact', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true, success: true })
            }
            let formFields = Object.assign({}, this.state.formFields);
            formFields.name = '';
            formFields.email = '';
            formFields.phone = '';
            formFields.text = '';
            formFields.preferred = '';
            formFields.service = 'Individual';
            this.setState({formFields});
        });
    }

    serviceChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.service = e.target.value;
        this.setState({formFields});
    }

    nameChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.name = e.target.value;
        this.setState({formFields});
    }

    preferredChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.preferred = e.target.value;
        this.setState({formFields});
    }

    emailChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.email = e.target.value;
        this.setState({formFields});
    }

    phoneChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.phone = e.target.value;
        this.setState({formFields});
    }

    textChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.text = e.target.value;
        this.setState({formFields});
    }

    onHideSuccess = () => {
        this.setState({submitted: false});
    }

    successMessage = () => {
        if (this.state.submitted){
            this.setState({ open: false });
        }
    }

    render(){
        let closeModal = () => this.setState({ open: false, success: false });
        let openModal = () => this.setState({ open: true, success: false });

        let body;

        if(this.state.success)
        {
            body = (
                <React.Fragment>
                    <Modal.Header>
                        <Modal.Title id='ModalHeader'>Schedule an Appointment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Thank you, your message has been sent. The therapist will be in contact with you soon.</p>
                    <form id="contactForm" onSubmit={this.onSubmit}>
                                    <div className="row">
                                        

                                        <div className="col-lg-12 col-md-12">
                                            <Modal.Dismiss className='btn btn-default'>Close</Modal.Dismiss>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                    </Modal.Body>
                </React.Fragment>
            );
        }
        else
        {
            body = (
                <React.Fragment>
                <Modal.Header>
                        <Modal.Title id='ModalHeader'>Schedule an Appointment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Please fill out the form to request an appointment and I will contact you shortly.  It may take up to 2 business days for a response.  Thank you!</p>
                    <form id="contactForm" onSubmit={this.onSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="service">What service would you like?</label>
                                                <select
                                                    className="form-control"
                                                    name="service"
                                                    id="service"
                                                    required={true}
                                                    data-error="Please select a service"
                                                    value={this.state.formFields.service}
                                                    onChange={this.serviceChangeHandler}>
                                                    <option value="Individual">Individual Counseling</option>
                                                    <option value="Couples">Couples Counseling</option>
                                                    <option value="ChildrenAdolescents">Children / Adolescents Counseling</option>
                                                    <option value="EMDR">EMDR Therapy</option>
                                                    <option value="Online">Online Counseling</option>
                                                </select>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="name" 
                                                    id="name" 
                                                    required={true}
                                                    data-error="Please enter your name" 
                                                    value={this.state.formFields.name}
                                                    onChange={this.nameChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input 
                                                    type="email" 
                                                    className="form-control" 
                                                    name="email" 
                                                    id="email" 
                                                    required={true}
                                                    data-error="Please enter your email" 
                                                    value={this.state.formFields.email}
                                                    onChange={this.emailChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="number">Phone Number</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="number" 
                                                    id="number" 
                                                    required={true}
                                                    data-error="Please enter your number" 
                                                    value={this.state.formFields.phone}
                                                    onChange={this.phoneChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="message">What are you seeking therapy for?  Also, if you are using insurance, please include the name of your insurance.</label>
                                                <textarea 
                                                    name="message" 
                                                    className="form-control" 
                                                    id="message" 
                                                    cols="30" 
                                                    rows="4" 
                                                    required={true}
                                                    data-error="Write your message" 
                                                    value={this.state.formFields.text}
                                                    onChange={this.textChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="preferred">Preferred Time / Day Availability</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="preferred" 
                                                    id="preferred" 
                                                    required={false}
                                                    data-error="Please enter your preferred availability" 
                                                    value={this.state.formFields.preferred}
                                                    onChange={this.preferredChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary">Send</button>
                                            <Modal.Dismiss className='btn btn-default'>Close</Modal.Dismiss>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                    </Modal.Body>
                </React.Fragment>
            );
        }

        return (
            <div>
                <Link to="#" className="btn btn-primary schedule-btn" onClick={openModal}>Schedule Now</Link>
                <Modal
                    show={this.state.open}
                    onHide={closeModal}
                    aria-labelledby="ModalHeader"
                    >
                    {body}
                </Modal>
            </div>
        );
    }
}
 
export default ScheduleAppointment;